import React from 'react';
import { Formik, Form, Field } from 'formik';
import { navigate } from 'gatsby';

import { createContact, emailIsValid } from '../../utils';
import { TextField } from '../form/TextField';
import { CheckboxField } from '../form/CheckboxField';

export const CourierForm = () => {
	const initialValues = {
		name: '',
		email: '',
		city: '',
		vehicle: '',
		phone: '',
		ev: false,
		student: false,
		accepttos: false,
	};

	const validate = (values) => {
		const errors = {};
		if (!values.name) {
			errors.name = 'Kötelező mező';
		} else if (!values.email) {
			errors.email = 'Kötelező mező';
		} else if (!values.city) {
			errors.city = 'Kötelező mező';
		} else if (!values.vehicle) {
			errors.vehicle = 'Kötelező mező';
		} else if (!values.phone) {
			errors.phone = 'Kötelező mező';
		} else if (!emailIsValid(values.email)) {
			errors.email = 'Helytelen e-mail formátum';
		} else if (!values.accepttos) {
			errors.accepttos = 'Kötelező mező';
		}
		return errors;
	};

	const onSubmit = async ({ _accepttos, ...values }, { setSubmitting }) => {
		const { data } = await createContact({ ...values, type: 'courier' });

		setSubmitting(false);

		if (data === 'CONTACT_EXISTS') {
			navigate('/error', { state: { type: 'already-applied' } });
			return;
		}

		navigate('/success');
	};

	return (
		<Formik
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
		>
			{({ isSubmitting }) => (
				<Form>
					<Field name="name" label="Teljes neved" component={TextField} />
					<Field
						type="email"
						name="email"
						label="E-mail címed"
						component={TextField}
					/>
					<Field name="city" label="Városod" component={TextField} />
					<Field
						name="vehicle"
						label="Szállítási eszközöd"
						component={TextField}
					/>
					<Field
						name="referral"
						label="Ajánló futár neve (Akitől hallottál rólunk)"
						component={TextField}
					/>
					<Field name="phone" label="Telefonszám" component={TextField} />
					<Field
						name="ev"
						label="Rendelkezem egyéni vállalkozással"
						component={CheckboxField}
					/>
					<Field
						name="student"
						label="Rendelkezem hallgatói jogviszonnyal"
						component={CheckboxField}
					/>
					<Field
						name="accepttos"
						label="Elfogadom az Adatvédelmi nyilatkozatot"
						component={CheckboxField}
					/>
					<button
						type="submit"
						className="btn btn-light my-3"
						disabled={isSubmitting}
					>
						Leadom a jelentkezésemet
					</button>
				</Form>
			)}
		</Formik>
	);
};
