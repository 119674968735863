import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';

import { CourierForm } from '../components/shared/CourierForm';
import CourierIllustration from '../components/common/CourierIllustration';

const CourierPage = ({ data }) => (
	<Layout footerImg={data.logo_footer}>
		<section id="courier-form" className="pb-0 bg-white">
			<div className="container">
				<div className="row pt-sm-5">
					<div className="col-12 col-sm-6">
						<h2 className="text-center text-sm-left my-4 mt-sm-0 ml-md-0">
							Jelentkezz ViddL futárnak
						</h2>
						<CourierForm />
					</div>
					<div className="col-12 col-sm-6 d-none d-sm-block text-center pt-5">
						<CourierIllustration />
						<p className="mt-5">
							Szolgáltatásunkba folyamatosan vonjuk be jelentkező futárjainkat.
						</p>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default CourierPage;
